import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_CADS } from "graphql/query/stand-q.gql";

import { Icons } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";


const { Option } = Select;

type TStandCADSelectProps = {
    standId         : TID,
    cadId          ?: TID
    highestVersion ?: boolean
};

const StandCadSelect : React.FC<TStandCADSelectProps> = ({
        cadId,
        standId,
        highestVersion,
        ...props
    }) : JSX.Element => {

    const { data, loading } = useQuery( GET_CADS, {
        variables : {
            where : {
                column : "STAND_ID",
                operator : "EQ",
                value : standId
            },
            orderBy : [
                { column : "VERSION", order : "DESC" },
                { column : "CORRECTION", order : "DESC" }
            ]
        },
        fetchPolicy : "cache-first"
    });

    const {
        cadsCursor : {
           edges : cads = []
        } = {}
    } = data || {};

    const existedVersions: number[] = [];

    return(
        <Select
            { ...props }
            loading={ loading }
            defaultValue={ cadId ? cadId : ""! }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { !cadId &&
                <Option key={ 0 } value={ "" }>Not selected</Option>
            }
            { cads.map(
                ({ node : { id, cad_number, version, correction  } }) => {

                    if(highestVersion && existedVersions.includes(version)){
                        return null;
                    }

                    existedVersions.push(version);

                    return(
                        <Option key={ id } value={ id } >
                            { cad_number } | version{ version }.{ correction }
                        </Option>
                    );
                }
            )
            }
        </Select>
    );
};

export default React.memo(StandCadSelect);