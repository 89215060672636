import StandCadStatusForm from "./Stand-cad-status-form";
import StandEditForm from "./Stand-edit-form";

import "./stand-forms.scss";


const StandForms = {
    Edit : StandEditForm,
    CadStatus : StandCadStatusForm
};

export default StandForms;