import React, { JSX } from "react";

import { Select } from "antd";

import StandHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { Icons } from "components/layout";

import type { TVariableItem } from "graphql/type/common-gql-types";


const { Option } = Select;

interface ICadStatusSelectProps {
    status      : string
}

const StandCadStatusSelect : React.FC<ICadStatusSelectProps> = ({ status, ...props }) : JSX.Element => {

    const variableLists = reactiveVarHelper().get('variableLists') as TVariableItem[];

    const cadVariables = variableLists.filter(
                                        (listItem :  TVariableItem) => listItem.group_name === 'cad status'
                                    ) ;
    return(
        <Select
            { ...props }
            className     = { `select-status-${StandHelpers.cad.statusClass(status)}` }
            defaultValue  = { status }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { !cadVariables.length &&
                <Option key={ 0 } value={ "" }>Not selected</Option>
            }
            { cadVariables.map(
                ({ id, value, slug }) => <Option key={ id } value={ slug }>{ value }</Option>)
            }
        </Select>
    );
};

export default React.memo(StandCadStatusSelect);