import React, { JSX } from "react";

import { useMutation } from "@apollo/client";
import { Button, Form, FormInstance, Input } from "antd";
import { generatePath, useNavigate } from "react-router-dom";

import { TASK_ATTACHMENT_UPLOAD, TASK_UPDATE } from "graphql/mutation/task-m.gql";

import TaskFields from "../fields";
import TaskHelpers from "../helpers";
import TaskLists from "../lists";
import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { UploadFile } from "components/service";
import Stand from "components/stand";
import Team from "components/team";
import { useNotification } from "components/use-hook";

import { EFileUploadModel, ETeamRoles } from "common/types";
import type { TID } from "graphql/type/common-gql-types";
import type { TTask } from "graphql/type/task-gql-types";


interface ITaskEditFormProps{
    standId       : TID
    teamId        : TID
    task          : TTask
    form         ?: FormInstance
    setTaskState  : (task : TTask) => void
    onUpdate      : (callback : () => void) => void
}

const { TextArea } = Input;
const { editForm } = TaskHelpers;

const typesWithCadNumber = [
    'task_type_first_cad',
    'task_type_new_cad_version'
];

const typesWithCadId = [
    'task_type_cad_correction'
];

const TaskEditForm : React.FC<ITaskEditFormProps> = (
    {
        standId,
        teamId,
        task,
        form,
        setTaskState,
        onUpdate,
    }) : JSX.Element => {

    const isCreate = false;

    const navigate = useNavigate(),
        { nError, nSuccess } = useNotification();

    const [ taskEdit, { loading } ] = useMutation(
        TASK_UPDATE,
        {
            update(_, { data }) {

                const { 'taskUpdate' : {
                    label,
                    message,
                    task : updatedTask,
                }  } = data;


                nSuccess(label, message);

                onUpdate && onUpdate(() => {
                    if(isCreate){
                        navigate(
                            generatePath(
                                ROUTES.STAND_ITEM_TASK_FULL,
                                {
                                    taskId : Number(updatedTask.id),
                                    eeId : Number(updatedTask.ee_id),
                                    standId : Number(standId)
                                }
                            )
                        );
                    }
                });

            },
            refetchQueries : task.status === "draft" ? [ 'GetTasks' ] : undefined,
            onError(error) {
                console.error(error);
            }
        }
    );

    const type : string = Form.useWatch<string, FormInstance>('type', form);

    const {
        relStand : {
            relCad
        }
    } = task;

    return (
        <div className = "stand-edit-form ">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                initialValues = {
                    !isCreate  && task ? editForm.initialData(task) : undefined
                }
                onFinish={(values) => {
                    if (!loading) {
                        taskEdit({
                            variables : {
                                input : editForm.prepareData(
                                    {
                                        ...values,
                                        id : task.id,
                                        stand_id : standId,
                                        status : task.status === 'draft' ? 'task_status_new' : task.status
                                    }
                                )
                            }
                        }).catch(nError);
                    }
                }}
            >
                <Form.Item
                    label = { "Task header" }
                    name  = "title"
                    rules = {[
                        {
                            required : true,
                            message : "required"
                        }
                    ]}
                >
                    <Input name = "task-title"/>
                </Form.Item>
                <div className = "grid-two-col">
                    <Form.Item
                        label = { "Importance" }
                        name  = "importance"
                        rules = {[
                            {
                                required : true,
                                message : "required"
                            }
                        ]}
                    >
                        <TaskFields.Importance />
                    </Form.Item>

                    <Form.Item
                        label = { "Assigned to" }
                        name  = "assignedTo"
                    >
                        <Team.Fields.UserSelect
                            modalTitle    = { "Choose Autocad engineer" }
                            teamId        = { Number(teamId) }
                            teamRole      = { ETeamRoles.DRAFTSMEN }
                            currentMember = { task && task.relAssignedTo ? task.relAssignedTo : undefined  }
                        />
                    </Form.Item>
                </div>
                <div className = "grid-two-col">
                    <Form.Item
                        label = { "Task type" }
                        name  = "type"
                        rules = {[
                            {
                                required : task.status === "draft",
                                message : "required"
                            }
                        ]}
                    >
                        <TaskFields.Type
                            disabled     = { task.status !== "draft" }
                            existedTypes = { !relCad ?
                                [ 'task_type_first_cad' ] :
                                [
                                    'task_type_new_cad_version',
                                    'task_type_cad_correction'
                                ]
                            }
                        />
                    </Form.Item>
                    { type && typesWithCadNumber.includes(type) &&
                        <Form.Item
                            label = { "Cad number" }
                            name  = "cad_number"
                            rules = {[
                                {
                                    required : true,
                                    message : "required"
                                }
                            ]}
                        >
                            <Input name = "cad-number"/>
                        </Form.Item>
                    }
                    { type && typesWithCadId.includes(type) &&
                        <Form.Item
                            label = { "Cad number" }
                            name  = "cad_id"
                            rules={[
                                {
                                    required : true,
                                    message : "required"
                                }
                            ]}
                        >
                            <Stand.Fields.CadSelect
                                standId        = { standId }
                                highestVersion = { true }
                            />
                        </Form.Item>
                    }
                </div>
                <Form.Item
                    label = {"Notes"}
                    name  = "description"
                >
                    <TextArea
                        placeholder = "Type description text"
                        autoSize    = {{ minRows : 6, maxRows : 6 }}
                    />
                </Form.Item>
                <div className="files">
                    <TaskLists.Files
                        files      = { task.relAttachments }
                        withDelete = { true }
                        onDelete   = { ( fileId ) => {
                            setTaskState({
                                ...task,
                                relAttachments : task.relAttachments.filter(
                                    ({ id }) => id !== fileId
                                ),
                            });
                        }}
                    />
                    <UploadFile
                        variables={{
                            input : {
                                model : EFileUploadModel.TASK,
                                model_id : task.id,
                            }
                        }}
                        accept   = { "*/*" }
                        onSuccess= { ({ attachmentCreate }) => {
                            setTaskState({
                                ...task,
                                ...attachmentCreate.model,
                            });
                        }}
                        mutation = { TASK_ATTACHMENT_UPLOAD }
                    >
                        {( { loading } ) => {
                            return(
                                <Button
                                    className = "attach-file inverted"
                                    type      = "text"
                                >
                                    <Icons.Attach loading={ loading } /> Attach file
                                </Button>
                            );
                        }}
                    </UploadFile>
                </div>
            </Form>
        </div>
    );
};

export default TaskEditForm;