import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Collapse } from 'antd';

import { GET_CADS } from "graphql/query/stand-q.gql";

import StandHelpers from "../helpers";
import { getDate } from "common/utils";
import { Icons } from "components/layout";
import { Loader } from "components/request-result";

import type { TID } from "graphql/type/common-gql-types";
import type { TStandCad } from "graphql/type/stand-gql-types";


interface IStandCadVersionProps {
    standId : TID
    currentCad : TStandCad
}

const StandCadVersionList : React.FC<IStandCadVersionProps> = ({ standId }) : JSX.Element => {

    const { data, loading } = useQuery( GET_CADS, {
        variables : {
            where : {
                column : "STAND_ID",
                operator : "EQ",
                value : standId
            },
            orderBy : [
                { column : "VERSION", order : "DESC" },
                { column : "CORRECTION", order : "DESC" }
            ]
        },
        fetchPolicy : "cache-first"
    });

    if(loading){
        return <Loader type="block" />;
    }

    const {
        cadsCursor : {
            edges : cadNodes = []
        } = {}
    } = data || {};


    return(
        <Collapse
            className  = "stand-cad-version-list"
            expandIcon = {
                () => <button className="plus-minus-toggle"/>
            }
            ghost
            accordion
            items={ 
                StandHelpers.cad.listMap(cadNodes).map(
                    ({ cad, versions }) => {
                    
                    return {
                        key : cad.version,
                        label :
                            <div className="cad-version">
                                <p>
                                    <span className="version">
                                        Version {cad.version}
                                    </span>
                                    <span className="number">
                                        | CAD {cad.cadNumber}
                                    </span>
                                </p>
                                <p className="count">{cad.count}</p>
                            </div>,
                        children :
                            <ul className="version-list">
                                {versions.map(
                                    (item) => {

                                        return (
                                            <li className = "version"
                                                key={ `${ item.id }-${ item.version }-${ item.correction }` }
                                            >
                                                <p>
                                                    Version { item.version }.{ item.correction }
                                                </p>
                                                { item.file_path ?
                                                    <>
                                                        <p className = "date">
                                                            { getDate.formated(item.updated_at) }
                                                        </p>
                                                        <a className = "link-text inverted"
                                                           href      = {item.file_path}
                                                           target    = "_blank"
                                                        >
                                                            <Icons.Download/>
                                                        </a>
                                                    </> :
                                                    <p className = "no-file">
                                                        No CAD File
                                                    </p>
                                                }
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                    };
                })
            }
        />
    );
};

export default StandCadVersionList;